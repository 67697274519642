import styles from "./SIgninStyles.module.css";
import { useRouter } from "next/router";
import { useAuth } from "@/context/AuthContext";
import { useDispatch } from "react-redux";
import { setPublicProfileData } from "@/redux/slices/userSlice";
import { indexedDBLocalPersistence, setPersistence } from "firebase/auth";
import { auth, db } from "@/_firebase/firebaseConfig";
import Analytics from "@/_firebase/analytics";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { doc, getDoc } from "firebase/firestore";
import { Mixpanel } from "@/helpers/mixpanel";

export default function AppleSignin(props: { onAndroidClick?: () => void }) {
  const router = useRouter();
  const { signInWithApple, user } = useAuth();
  const dispatch = useDispatch();

  function handleAppleSignin() {
    if (window.Android) {
      window.Android.triggerAppleLogin();
      props.onAndroidClick && props.onAndroidClick();
    } else {
      setPersistence(auth, indexedDBLocalPersistence)
        .then(() => {
          return signInWithApple()
            .then(async (res) => {
              Mixpanel?.track("User authenticated", {
                type: "Apple",
                referrer_type: localStorage.getItem("referrer_type"),
                referrer_uid: localStorage.getItem("recommenderUid"),
                email: res.user.email,
                app_type: window.Android ? "Android" : "web",
                was_recommended: localStorage.getItem("recommenderUid")
                  ? true
                  : false,
              });
              if (user?.uid) {
                Mixpanel?.people.set(
                  {
                    "auth type": "Apple",
                    initial_app_type: "Android",
                    Email: res.user.email,
                    UID: res.user.uid,
                    "Dimensions Completed": [],
                    referrer_type: localStorage.getItem("referrer_type"),
                    referrer_uid: localStorage.getItem("recommenderUid"),
                  },
                  res.user.uid
                );
                const id = res.user.uid;
                const memberRef = doc(db, `members/${id}`);
                const docSnap = await getDoc(memberRef);
                if (docSnap.exists() && docSnap.data()) {
                  ///check for public profile
                  const publicProfileRef = doc(db, `/publicProfiles/${id}`);
                  const publicSnap = await getDoc(publicProfileRef);
                  if (publicSnap.exists() && publicSnap.data()) {
                    //account already created
                    dispatch(
                      setPublicProfileData(publicSnap.data() as PublicProfile)
                    );
                    router.push(`/app/profile`);
                  } else {
                    //not created
                    router.push("/app/onboarding");
                  }
                } else {
                  ///new account--Do Google analytics
                  Analytics({
                    name: `New User Signup`,
                    args: {
                      uid: id,
                      provider: "Google",
                      email: res.user.email,
                    },
                  });
                  router.push("/app/onboarding");
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div onClick={() => handleAppleSignin()} className={styles.main}>
      <div className={styles.iconDIv}>
        <svg
          width="20"
          height="24"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.422 20.537C16.448 21.9922 15.4152 23.4122 13.8427 23.4357C12.2701 23.4709 11.7655 22.5086 9.98173 22.5086C8.18621 22.5086 7.63464 23.4122 6.1442 23.4709C4.60686 23.5296 3.44505 21.9218 2.45927 20.5018C0.452507 17.6032 -1.08484 12.2636 0.980603 8.6725C2.00159 6.88871 3.83232 5.76211 5.81561 5.7269C7.31778 5.70343 8.74951 6.74789 9.67661 6.74789C10.592 6.74789 12.3288 5.49219 14.1478 5.67996C14.9106 5.71517 17.0465 5.98508 18.4195 8.00358C18.3139 8.07399 15.8729 9.50572 15.8964 12.4748C15.9316 16.0189 19.0063 17.2042 19.0415 17.2159C19.0063 17.2981 18.5486 18.9058 17.422 20.537ZM10.7211 1.76032C11.5778 0.786275 12.9977 0.0469418 14.1713 0C14.3238 1.37305 13.7723 2.75783 12.9508 3.74361C12.1411 4.74112 10.8032 5.51566 9.48884 5.41004C9.31281 4.06047 9.97 2.65221 10.7211 1.76032Z"
            fill="#FFFFF2"
          />
        </svg>
      </div>
      <h5 className={styles.signinText}>Sign in with Apple</h5>
    </div>
  );
}
