import { app } from "@/_firebase/firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";

type AnalayticsProps = {
  name: string;
  args?: {};
};

export default function Analytics(props: AnalayticsProps) {
  let analytics: any;
  if (typeof window !== "undefined") {
    analytics = getAnalytics(app);
  }

  return logEvent(analytics, props.name, props.args);
}
