import styles from "../../styles/app/AppIndex.module.css";

function Button(props: { onClick: () => void }) {
  return (
    <div className={styles.buttonDiv}>
      <button onClick={() => props.onClick()} className={styles.button}>
        Continue
      </button>
    </div>
  );
}

function SlideCircles(props: { index: number }) {
  return (
    <div className={styles.circleDiv}>
      <div
        className={props.index === 0 ? styles.circleActive : styles.circle}
      ></div>
      <div
        className={props.index === 1 ? styles.circleActive : styles.circle}
      ></div>
      <div
        className={props.index === 2 ? styles.circleActive : styles.circle}
      ></div>
      <div
        className={props.index === 3 ? styles.circleActive : styles.circle}
      ></div>
    </div>
  );
}

export default function AppIndexStepOne(props: {
  onContinue: () => void;
  index: number;
}) {
  return (
    <div className={styles.stepOneTopDiv}>
      <Button onClick={() => props.onContinue()} />
      <SlideCircles index={props.index} />
    </div>
  );
}

export function AppIndexStepTwo(props: {
  onContinue: () => void;
  index: number;
}) {
  return (
    <div className={styles.stepTwoTopDiv}>
      <Button onClick={() => props.onContinue()} />
      <SlideCircles index={props.index} />
    </div>
  );
}

export function AppIndexStepThree(props: {
  onContinue: () => void;
  index: number;
}) {
  return (
    <div className={styles.stepThreeTopDiv}>
      <Button onClick={() => props.onContinue()} />
      <SlideCircles index={props.index} />
    </div>
  );
}

export function AppIndexStepFour(props: {
  onContinue: () => void;
  index: number;
}) {
  return (
    <div className={styles.stepFourTopDiv}>
      <Button onClick={() => props.onContinue()} />
      <SlideCircles index={props.index} />
    </div>
  );
}
