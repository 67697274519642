import { ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Swiper, SwiperSlide } from "swiper/react";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { useDispatch } from "react-redux";
import { doc, getDoc } from "firebase/firestore";
import { indexedDBLocalPersistence, setPersistence } from "firebase/auth";

import styles from "../../styles/app/AppIndex.module.css";
import "swiper/css";

import { useAuth } from "@/context/AuthContext";

import LoadingScreen from "@/components/assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";
import useIsMobile from "@/helpers/useIsMobile";
import AppIndexStepOne, {
  AppIndexStepFour,
  AppIndexStepThree,
  AppIndexStepTwo,
} from "@/components/AppIndex/AppIndexSteps";
import ModalAuthHeader from "@/components/shared/AuthenticatedHeader/ModalAuthHeader";
import GoogleSignin from "@/components/home/signinButtons/GoogleSignin";
import AppleSignin from "@/components/home/signinButtons/AppleSignin";
import EmailSignin from "@/components/home/signinButtons/EmailSignin";
import Appstore from "@/components/home/Appstore";
import { Mixpanel } from "@/helpers/mixpanel";
import { db } from "@/_firebase/firebaseConfig";
import { setPublicProfileData } from "@/redux/slices/userSlice";
import axios from "axios";
import AlertHandler from "@/helpers/AlertHandler";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";

export default function AppHome() {
  const [loading, setLoading] = useState<boolean>(false);

  const [step, setStep] = useState<number>(0);
  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const { signInUsingCustomToken, auth, user } = useAuth();

  const [isIphone, setIsIphone] = useState<boolean>(false);

  const { isAlertOpen, setAlertComponent } = useAlertContext();

  const router = useRouter();

  useEffect(() => {
    const { peerAssessmentLinkId } = router.query;
    if (peerAssessmentLinkId) {
      router.push("/app/peer-assessment-index");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  useEffect(() => {
    Mixpanel?.track("App opened", {});
    const handleMessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);

        console.log("Message from native layer on google login:", event.data);

        if (data.customToken) {
          console.log("Custom token is: ", data.customToken);
          try {
            setPersistence(auth, indexedDBLocalPersistence).then(async () => {
              const options = {
                method: "POST",
                data: { token: data.customToken },
                url: "https://www.dimensional.me/api/token",
              };
              const tokenResult = await axios(options);
              if (tokenResult.data) {
                const res = await signInUsingCustomToken(
                  tokenResult.data.token
                );
                setLoading(false);
                Mixpanel?.track("User authenticated", {
                  type: "Google",
                  email: res.user.email,
                  referrer_type: localStorage.getItem("referrer_type"),
                  referrer_uid: localStorage.getItem("recommenderUid"),
                  app_type: window.Android ? "Android" : "web",
                  was_recommended: localStorage.getItem("recommenderUid")
                    ? true
                    : false,
                });
                Mixpanel?.people.set(
                  {
                    "auth type": "Google",
                    initial_app_type: "Android",
                    Email: res.user.email,
                    UID: res.user.uid,
                    "Dimensions Completed": [],
                    referrer_type: localStorage.getItem("referrer_type"),
                    referrer_uid: localStorage.getItem("recommenderUid"),
                  },
                  res.user.uid
                );
              } else {
                console.log("Error getting token");
                setLoading(false);
              }
            });
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        }
        if (data.failedSignedIn) {
          if (window.Android) {
            console.log("Failed google signin");
            window.Android.triggerGoogleLogin();
          }
        }
        if (data.cancelledSignin) {
          console.log("Google Singin Cancelled");
          setAlertComponent(
            <Alert
              elementName="Alert"
              message="Failed to sign into Google. Try again!"
              type="warning"
            />,
            4000
          );
          setLoading(false);
        }
      } catch (error: any) {
        console.log(error);
        setLoading(false);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.userAgent) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    }

    if (
      !window.location.href.includes("dimensional-web-staging") &&
      !window.location.href.includes("localhost")
    ) {
      setIsIphone(iOS());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedSlides = [
    <AppIndexStepOne key={0} onContinue={() => setStep(1)} index={index} />,
    <AppIndexStepTwo key={1} onContinue={() => setStep(1)} index={index} />,
    <AppIndexStepThree key={2} onContinue={() => setStep(1)} index={index} />,
    <AppIndexStepFour key={3} onContinue={() => setStep(1)} index={index} />,
  ].map((comp) => {
    return <SwiperSlide key={comp.key}>{comp}</SwiperSlide>;
  });

  if (loading) {
    return (
      <AppIndexProtectedRoute>
        <LoadingScreen message="" />
      </AppIndexProtectedRoute>
    );
  }

  if (step === 0) {
    return (
      <AppIndexProtectedRoute>
        <main className={styles.main}>
          <Swiper
            slidesPerView={1}
            touchReleaseOnEdges={true}
            onSlideChange={(e) => {
              setIndex(e.activeIndex);
            }}
            scrollbar={{ draggable: true }}
            threshold={1}
            onSwiper={setMySwiperRef}
          >
            {mappedSlides}
          </Swiper>
        </main>
      </AppIndexProtectedRoute>
    );
  }

  return (
    <>
      {isAlertOpen && <AlertHandler />}
      <AppIndexProtectedRoute>
        <main className={styles.main}>
          <div className={styles.wrapper}>
            <div style={{ backgroundColor: "#181716" }}>
              <ModalAuthHeader
                onBack={() => setStep(0)}
                noLine
                title="Sign on"
              />
            </div>
            <div className={styles.buttonsDiv}>
              {!isIphone ? (
                <>
                  <GoogleSignin
                    active
                    onAndroidClick={() => setLoading(true)}
                  />
                  <AppleSignin onAndroidClick={() => setLoading(true)} />
                  <EmailSignin />
                  <p
                    className={styles.verifiedButtonLink}
                    onClick={() => router.push("/app/emailAndPassword")}
                  >
                    Verified login
                  </p>
                </>
              ) : (
                <Appstore link />
              )}
            </div>
          </div>
        </main>
      </AppIndexProtectedRoute>
    </>
  );
}

function AppIndexProtectedRoute(props: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth();
  const router = useRouter();
  const dispatch = useDispatch();

  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;

  useEffect(() => {
    ///checking for peer assessment from Android device
    if (window.Android?.checkForPeerAssessment) {
      window.Android?.checkForPeerAssessment();
    }

    if (window.Android?.checkForRecommenderUid) {
      window.Android?.checkForRecommenderUid();
    }

    if (window.Android?.checkForReferrerType) {
      window.Android?.checkForReferrerType();
    }

    const checkLocalStorage = () => {
      const peerAssessmentLS = localStorage.getItem("peerAssessmentLinkId");
      if (peerAssessmentLS) {
        router.push("/app/peer-assessment-index");
      }

      if (
        localStorage.getItem("recommenderUid") &&
        localStorage.getItem("referrer_type") &&
        user?.uid
      ) {
        router.push(`/app/profile/${localStorage.getItem("recommenderUid")}`);
      }
    };

    const intervalId = setInterval(checkLocalStorage, 500);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const authPromise = new Promise<void>((res, rej) => {
      if (user) {
        res();
      } else {
        setTimeout(() => {
          rej();
        }, 2000);
      }
    });

    authPromise
      .then(async () => {
        if (user?.uid) {
          Mixpanel?.people.set(
            {
              "auth type": "Google",
              initial_app_type: "Android",
              Email: user.email,
              UID: user.uid,
              "Dimensions Completed": [],
              referrer_type: localStorage.getItem("referrer_type"),
              referrer_uid: localStorage.getItem("recommenderUid"),
            },
            user.uid
          );

          const memberRef = doc(db, `members/${user.uid}`);
          const docSnap = await getDoc(memberRef);

          if (docSnap.exists() && docSnap.data()) {
            // Check for public profile
            const publicProfileRef = doc(db, `/publicProfiles/${user.uid}`);
            const publicSnap = await getDoc(publicProfileRef);

            if (publicSnap.exists() && publicSnap.data()) {
              // Account already created
              dispatch(
                setPublicProfileData(publicSnap.data() as PublicProfile)
              );
              router.push(`/app/home`);
            } else {
              // Public profile not created
              router.push("/app/onboarding");
            }
          } else {
            // New account
            router.push("/app/onboarding");
          }
        }
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!window.Android && environment === "prod") {
    return <LoadingScreen message="" />;
  }

  if (loading) {
    return <LoadingScreen message="" />;
  }

  return <>{props.children}</>;
}
